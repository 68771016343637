import RoleType from 'models/role';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

export const adminUser = (allRoles: RoleType[], id: string) => {
  const userRole = allRoles.find((obj: RoleType) => obj.id === id);
  if (userRole?.name === 'Super Admin') {
    return true;
  } else {
    return false;
  }
};

export const salesManager = (allRoles: RoleType[], id: string) => {
  const userRole = allRoles.find((obj: RoleType) => obj.id === id);
  if (userRole?.name === 'Sales Manager') {
    return true;
  } else {
    return false;
  }
};

export const accountingRole = (allRoles: RoleType[], id: string) => {
  const userRole = allRoles.find((obj: RoleType) => obj.id === id);
  if (userRole?.name === 'Accounting') {
    return true;
  } else {
    return false;
  }
};

export const travelConsultantRole = (allRoles: RoleType[], id: string) => {
  const userRole = allRoles.find((obj: RoleType) => obj.id === id);
  if (userRole?.name === 'Travel Consultant') {
    return true;
  } else {
    return false;
  }
};

export const travelCoordinatorRole = (allRoles: RoleType[], id: string) => {
  const userRole = allRoles.find((obj: RoleType) => obj.id === id);
  if (userRole?.name === 'Travel Coordinator') {
    return true;
  } else {
    return false;
  }
};

export const isSameOrBeforeDay = (date1: Date, date2: Date): boolean => {
  const utcDate1 = dayjs(date1).utc();
  const utcDate2 = dayjs(date2).utc();

  return utcDate1.isSame(utcDate2, 'day') || utcDate1.isBefore(utcDate2, 'day');
};

export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  let currentDate = dayjs(startDate).utc();
  const utcEndDate = dayjs(endDate).utc();

  while (isSameOrBeforeDay(currentDate.toDate(), utcEndDate.toDate())) {
    dates.push(currentDate.toDate());
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};

export const addHours = (date: Date, hours: number): Date => {
  return dayjs(date).utc().add(hours, 'hour').toDate();
};

export const isEmpty = (obj: any): boolean => {
  if (typeof obj === 'string' && obj.trim() === '{}') {
    return true;
  }

  if (Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

export const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const fullDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function isUUID(str: string) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}
