import { Components, autocompleteClasses, inputBaseClasses, inputLabelClasses } from '@mui/material';

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  defaultProps: {
    autoHighlight: true,
    autoComplete: true
  },
  styleOverrides: {
    root: {
      [`& .${autocompleteClasses.inputRoot}`]: {
        paddingLeft: '20px',
        paddingRight: '14px',
        '& .MuiInputBase-input': {
          paddingLeft: 0
        },
        [`& .${inputBaseClasses.root}`]: {
          paddingLeft: '20px'
        }
      },
      [`& .${inputLabelClasses.root}`]: {
        marginLeft: '8px'
      },
      [`& .${autocompleteClasses.clearIndicator}`]: {
        right: '0px'
      },
      [`& .${autocompleteClasses.popupIndicator}`]: {
        right: '0px'
      }
    },
    popper: {
      [`& .${autocompleteClasses.paper}`]: {
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
      },
      [`& .${autocompleteClasses.option}`]: {
        paddingLeft: '20px'
      }
    }
  }
};

export default MuiAutocomplete;
