import { alpha, createTheme } from '@mui/material/styles';
import MuiAutocomplete from './components/MuiAutocomplete';
import MuiButton from './components/MuiButton';
import MuiCheckbox from './components/MuiCheckbox';
import MuiCssBaseline from './components/MuiCssBaseline';
import MuiDialog from './components/MuiDialog';
import MuiIconButton from './components/MuiIconButton';
import MuiLinearProgress from './components/MuiLinearProgress';
import MuiMenuItem from './components/MuiMenuItem';
import MuiTab from './components/MuiTab';
import MuiTable from './components/MuiTable';
import MuiTableBody from './components/MuiTableBody';
import MuiTableHead from './components/MuiTableHead';
import MuiTableRow from './components/MuiTableRow';
import MuiTabs from './components/MuiTabs';
import MuiTextField from './components/MuiTextField';
import MuiTooltip from './components/MuiTooltip';
import MuiChip from './components/MuiChip';

export const customColors = {
  text: {
    primary: '#000000',
    gray: '#A6A6A6',
    white: '#FFFFFF',
    black60: '#666666',
    orange: '#D77842',
    green: '#556447'
  },
  //olive
  ol: {
    100: '#556447',
    70: '#BFC8B8',
    40: '#DBE0D6'
  },
  background: {
    main: '#F5F5F5',
    ol: '#556447',
    white: '#FFFFFF'
  },
  border: {
    o40: '#DBE0D6',
    orange: '#D77842',
    green: '#556447'
  }
};

export const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Barlow Condensed',
    fontSize: 16,
    h1: {
      fontSize: 34,
      lineHeight: '41.17px',
      fontWeight: 700,
      color: '#000000'
    },
    mainCaptionSemi: {
      fontSize: 34,
      lineHeight: '47.6px',
      fontWeight: 700,
      fontFamily: 'Barlow Semi Condensed',
      letterSpacing: '0.04em'
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: 21,
      lineHeight: '25.43px'
    },
    subtitle2: {
      fontFamily: 'Barlow Semi Condensed',
      fontWeight: 400,
      fontSize: 21,
      lineHeight: '29.4px'
    },
    subtitle3: {
      fontFamily: 'Barlow Condensed',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22.4px'
    },
    body1: {
      fontFamily: 'Barlow Condensed',
      fontSize: 21,
      fontWeight: 400,
      lineHeight: '29.4px'
    },
    body2: {
      fontFamily: 'Barlow Condensed',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22.4px'
    },
    body1Semi: {
      fontFamily: 'Barlow Semi Condensed',
      fontSize: 21,
      fontWeight: 400,
      lineHeight: '29.4px'
    },
    sidebarLabel: {
      fontFamily: 'Barlow Condensed',
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '18.2px'
    },
    caption: {
      fontFamily: 'Barlow Semi Condensed',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22.4px'
    },
    button: {
      fontFamily: 'Barlow Condensed',
      fontSize: 21,
      fontWeight: 700,
      letterSpacing: '0.04em',
      lineHeight: '29.4px',
      color: '#FFFFFF'
    },
    h2: {
      fontFamily: 'Barlow Condensed',
      fontSize: '21px',
      fontWeight: '700',
      lineHeight: '29.4px',
      letterSpacing: '0.04em'
    },
    modalTitle: {
      fontFamily: 'Barlow Condensed',
      fontSize: '26px',
      fontWeight: '700',
      lineHeight: '36.4px',
      letterSpacing: '0.04em'
    }
  },
  palette: {
    primary: {
      main: '#556447',
      light: '#ADB9A3',
      dark: alpha('#556447', 1),
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#EB5757',
      light: alpha('#EB5757', 0.5),
      dark: alpha('#EB5757', 1),
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#D77842',
      light: '#F2C94C',
      dark: alpha('#D77842', 1),
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#F3F3F3',
      ol: '#556447',
      paper: '#FFFFFF',
      column: '#FFFFFF'
    },
    customColors: {
      ol: {
        100: '#556447',
        70: '#BFC8B8',
        40: '#DBE0D6',
        30: '#E5E5E5',
        20: 'rgba(164,177,153,0.35)',
        10: 'rgba(164,177,153,0.08)'
      },
      grey: {
        100: '#F3F3F3',
        70: '#A6A6A6',
        50: '#F8F8F8',
        40: '#ffffff66'
      },
      orange: '#D77842'
    },
    border: {
      o40: '#DBE0D6',
      orange: '#D77842',
      green: '#556447'
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
      disabled: '#A6A6A6',
      white: '#FFFFFF',
      orange: '#D77842',
      green: '#556447',
      error: '#EB5757'
    }
  },
  components: {
    MuiTooltip,
    MuiTextField,
    MuiCheckbox,
    MuiLinearProgress,
    MuiCssBaseline,
    MuiTableBody,
    MuiTableHead,
    MuiTabs,
    MuiTab,
    MuiButton,
    MuiIconButton,
    MuiMenuItem,
    MuiTableRow,
    MuiTable,
    MuiDialog,
    MuiAutocomplete,
    MuiChip
  },
  spacing: 8
});
