import * as yup from 'yup';
import { emailValidation, passwordConfirmRegisterValidation, passwordRegisterValidation } from 'utils/validation';

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordRegisterValidation,
  confirmPassword: passwordConfirmRegisterValidation
});

export default schema;
