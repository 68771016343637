import axios, { AxiosInstance } from 'axios';

const BASE_URL = process.env.REACT_APP_API_SERVER;

export const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL || 'https://wt-dev-be.westlinkclient.com/api',
  withCredentials: true,
  headers: {
    Accept: '*/*'
  }
});

instance.interceptors.request.use((config) => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('STORAGE_AUTH');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return config;
});

export default instance;
