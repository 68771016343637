import { instance } from '.';

export const rolesAPI = {
  allRoles: async (search_text?: string, type?: string[]) => {
    let queryParams = `?`;

    if (search_text && search_text?.length > 0) {
      queryParams = `${queryParams}&searchText=${search_text}`;
    }
    if (type && type?.length > 0) {
      queryParams = `${queryParams}&type=${type.join(',')}`;
    }

    return instance.get(`/roles${queryParams}`).then((res) => res);
  }
};
