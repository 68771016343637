import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconWrap } from './styles';
import { Typography } from '@mui/material';

interface SidebarItemProps {
  children: ReactNode;
  to: string;
  active?: boolean;
  label?: string;
}

const SidebarItem = ({ children, to, active = false, label }: SidebarItemProps) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Link style={{ textDecoration: 'none' }} to={to}>
      <IconWrap active={active}>
        {children}
        {label && (
          <Typography variant='sidebarLabel' component='span'>
            {label}
          </Typography>
        )}
      </IconWrap>
    </Link>
  );
};

export default SidebarItem;
