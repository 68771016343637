import * as yup from 'yup';

const registerPasswordMessageError =
  'Passwords do not match or do not meet complexity requirements. Password must include 1 capital letter, 1 lower case letter, 1 special symbol and at least 8 characters.';

export const passwordConfirmRegisterValidation = yup.string().required('Password is required');

export const passwordRegisterValidation = yup
  .string()
  .required('Password is required')
  .max(50, registerPasswordMessageError)
  .matches(/^.{8,}$/, registerPasswordMessageError)
  .matches(/^(?=.*[a-z]).{8,}$/, registerPasswordMessageError)
  .matches(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/, registerPasswordMessageError)
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/, registerPasswordMessageError);

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .max(50, 'Please input correct email and password')
  .matches(/^.{8,}$/, 'Please input correct email and password')
  .matches(/^(?=.*[a-z]).{8,}$/, 'Please input correct email and password')
  .matches(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Please input correct email and password')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
    'Please input correct email and password'
  );

export const emailValidation = yup
  .string()
  .required('Email is required')
  .max(100, 'Email should not exceed 100 characters')
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email isn't valid");
