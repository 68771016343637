import { Components } from '@mui/material';

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    label: {
      letterSpacing: '0.04em',
      fontWeight: 700,
      fontFamily: 'Barlow Condensed',
      fontSize: '13px',
      lineHeight: '18.2px'
    }
  }
};

export default MuiChip;
