import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as Password } from 'assets/svg/password-icon.svg';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'services/authAPI';
import './auth.css';
import FloatingLabelInput from './FloatingLabelInput';
import schema from './schema/SetPasswordSchema';

export const SetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState('');
  const [notMatch, setNotMatch] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);

  const onSubmit = async (data: any) => {
    const { password, confirmPassword } = data;
    const noMatched = confirmPassword !== password ? true : false;
    if (!noMatched) {
      try {
        await authAPI.passwordReset({ token, password });
        navigate('/');
      } catch (error: any) {
        setAuthError(error?.response?.data?.message || 'Something went wrong');
      }
    }
    setNotMatch(noMatched);
  };

  useEffect(() => {
    if (location.search && location.search.split('?token=')[1]) {
      setToken(location.search.split('?token=')[1]);
    }
  }, []);

  return (
    <form className='auth-form' onSubmit={handleSubmit(onSubmit)}>
      <h3 className='auth-title'>Set new password</h3>
      <FloatingLabelInput
        register={register}
        required={false}
        identification={'password'}
        icon={<Password />}
        placeholder={'New password'}
        type='password'
      />
      {errors.password && <span className='error-text'>{errors.password.message}</span>}
      <FloatingLabelInput
        register={register}
        required={false}
        identification={'confirmPassword'}
        icon={<Password />}
        placeholder={'Confirm password'}
        type='password'
        setFormValue={setValue}
      />
      {authError && <span className='error-text'>{authError || 'Something went wrong'}</span>}
      {notMatch && <span className='error-text'>Confirm password is not match!</span>}
      <button type='submit'>reset password and login</button>
    </form>
  );
};
