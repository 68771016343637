import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from 'reactQueryKeys';
import { rolesAPI } from 'services/rolesAPI';
import RoleType from 'models/role';

const getRoles = async (search_text?: string, type?: string[]): Promise<RoleType[]> => {
  const rolesRes = await rolesAPI.allRoles(search_text, type);

  return rolesRes?.data;
};

export const useRoles = (search_text?: string, type?: string[]): RoleType[] => {
  const { data: roles } = useQuery({
    queryKey: [ReactQueryKeys.ROLES, search_text, type],
    queryFn: () => getRoles(search_text, type),
    staleTime: Infinity
  });

  return roles || [];
};
