import './auth.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/svg/logo-large.svg';

type TAuthFooter = {
  title: string;
  buttonText: string;
  buttonUrl: string;
};

type TProps = {
  children: React.ReactNode;
  footer?: TAuthFooter;
};

export const AuthTemplate = ({ children, footer }: TProps) => {
  const navigate = useNavigate();

  return (
    <div className='auth-template-wrapper'>
      <div className='auth-template-content'>
        <Logo />
        <div className='auth-template-form-wrapper'>{children}</div>

        {footer ? (
          <div className='auth-footer'>
            <h4>{footer.title}</h4>
            <button className='' onClick={() => navigate(footer.buttonUrl)}>
              {footer.buttonText}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
