import { Components } from '@mui/material';

const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: '#E4E8E0',
        borderRadius: '10px'
      }
    }
  }
};

export default MuiIconButton;
