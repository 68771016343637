import React from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import SideBar from 'components/SideBar';
import { Wrapper } from './styles';

const Layout = () => {
  return (
    <div style={{ height: '100%' }}>
      <CssBaseline />
      <Wrapper>
        <SideBar />
        <main className='root'>
          <Outlet />
        </main>
      </Wrapper>
    </div>
  );
};

export default Layout;
