import React from 'react';
import { AuthTemplate } from 'components/Auth/AuthTemplate';
import { ResetPasswordForm } from 'components/Auth/ResetPassword';

const RequestResetPassword = () => {
  return (
    <AuthTemplate
      footer={{
        title: 'Back to login?',
        buttonText: 'login',
        buttonUrl: '/login'
      }}
    >
      <ResetPasswordForm />
    </AuthTemplate>
  );
};

export default RequestResetPassword;
