import { Components } from '@mui/material';

const MuiTableRow: Components['MuiTableRow'] = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      marginTop: '10px',

      '&.MuiTableRow-hover:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}`
      },

      '&.table-row': {
        borderRadius: '10px',
        backgroundColor: `${theme.palette.customColors.grey[50]}`
      },
      '&.table-card': {
        backgroundColor: 'inherit !important',
        borderTop: `1px solid ${theme.palette.border.o40} !important`
      }
    })
  }
};

export default MuiTableRow;
