import { yupResolver } from '@hookform/resolvers/yup';
import { filledInputClasses, Stack, TextField, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactComponent as Email } from 'assets/svg/email-icon.svg';
import { ReactComponent as Password } from 'assets/svg/password-icon.svg';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'services/authAPI';
import './auth.css';
import schema from './schema/SignupFormSchema';

export const SignupForm = () => {
  const { palette } = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });
  const [authError, setAuthError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const textFieldStyle: SxProps = {
    width: 1,
    [`& .${filledInputClasses.root}`]: {
      mt: 0,
      ':hover': {
        backgroundColor: palette.background.paper
      },
      border: 0
    }
  };

  useEffect(() => {
    if (errors.email || errors.password || errors.confirmPassword) {
      document.getElementById(errors.email ? 'email' : errors.password ? 'password' : 'confirmPassword')?.focus();
    }
  }, [errors]);

  useEffect(() => {
    if (location.search && location.search.split('?email=')[1]) {
      setValue('email', location.search.split('?email=')[1]);
    }
  }, []);

  const onSubmit = async (data: any): Promise<any> => {
    const { email, password, confirmPassword } = data;
    if (password !== confirmPassword) {
      setAuthError(`Password doesn't match.`);
      return;
    }

    try {
      await authAPI.signup({ email, password });
      navigate('/login');
    } catch (error: any) {
      setAuthError(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <form className='auth-form' onSubmit={handleSubmit(onSubmit)}>
      <h3 className='auth-title'>Complete signup</h3>
      <Stack
        sx={{
          px: 1.5,
          py: 0.2,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 3.2,
          border: `1px solid ${palette.border.o40}`
        }}
      >
        <Email />

        <TextField {...register('email')} type='email' label='Email' sx={textFieldStyle} />
      </Stack>
      {errors.email && <span className='error-text'>{errors.email.message}</span>}
      <Stack
        sx={{
          px: 1.5,
          py: 0.2,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 3.2,
          border: `1px solid ${palette.border.o40}`
        }}
      >
        <Password />

        <TextField {...register('password')} type='password' label='Password' sx={textFieldStyle} />
      </Stack>
      {errors.password && <span className='error-text'>{errors.password.message}</span>}
      <Stack
        sx={{
          px: 1.5,
          py: 0.2,
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 3.2,
          border: `1px solid ${palette.border.o40}`
        }}
      >
        <Password />

        <TextField {...register('confirmPassword')} type='password' label='Confirm password' sx={textFieldStyle} />
      </Stack>
      {errors.confirmPassword && <span className='error-text'>{errors.confirmPassword.message}</span>}
      {authError && <span className='error-text'>{authError || 'Something went wrong'}</span>}
      <button type='submit'>Complete signup</button>
    </form>
  );
};
